import React, { useState, useEffect, useRef} from 'react';
import './App.css';
import logo from './assets/logo-trans.png';

const DEFAULT_PAUSE = 2000; // Default pause of 2000 milliseconds (2 seconds)
//const animationDuration = 1000; // Duration of the animation in milliseconds

  
function App() {

  function getMatchingFirst(str1, str2) {
    if (str1.length === 0 || str2.length === 0) {
      return "";
    }
    for (let i = 0; i < str1.length; i++) {
      if (str1[i] !== str2[i]) {
        return str1.slice(0, i);
      }
    }
    return str1.slice(0, str1.length - str2.length + 1);
  }
  

  // Preprocess steps to add typing effect
  function preprocessSteps(steps) {
    const processedSteps = [];
  
    steps.forEach(step => {
      if (step.typing) {
        const typingText = step.typing;
        //const tokens = typingText.match(/{[^}]+}[^ ]*|./g); // Matches color sequences followed by characters or individual characters
        //const tokens = typingText.match(/{[^}]+}|./g); // Matches color sequences or individual characters
        // Matches color sequences, {TAB} token, or individual characters
        const tokens = typingText.match(/{[A-Z]{2}}|{[^}]+}|./g);
        console.log("token:" + tokens);
         // Insert an empty output step with the original step's pause prior the first token
         processedSteps.push({
          output: '',
          pause: step.pause
        });

        let currentLine = ''; // To store the current line up until {TAB}

        tokens.forEach((token, index) => {
          let extraPause = 0;
  
          if (token.startsWith('{TAB=')) {
            // Handle the special {TAB} token
            const [beforeTab, afterTab] = token.slice(5, -1).split(';');
            //const firstPart = beforeTab.charAt(0);
            const firstPart = getMatchingFirst(beforeTab,afterTab);
            const remainingChars = beforeTab.slice(firstPart.length);
  
            // Print the first character of beforeTab that are equal
            processedSteps.push({
              output: firstPart,
              pause: Math.random() * 400 + 900 // Random pause between 50ms and 150ms
            });
  
            // Append the first character to currentLine
            currentLine += firstPart;
  
            // Print the completion options on a new line
            processedSteps.push({
              output: `\n{GR}${afterTab}`,
              pause: 1000 // Custom pause for tab completion
            });
  
            // Print the entire line up to {TAB}, append the full beforeTab string
            const cleanedLine = currentLine.replace(/\{TAB=[^}]+\}/g, ''); // Remove any previous {TAB} constructs
          
            processedSteps.push({
              output: `\n${cleanedLine}${remainingChars}`,
              pause: Math.random() * 400 + 1200 // Random pause between 50ms and 150ms
            });
  
            // Add the remaining characters of beforeTab to currentLine
            currentLine += remainingChars;
  
          } else if (/[^a-zA-Z0-9 ]/.test(token)) { // Check if the token is a special character
            extraPause = 50; // Add 50ms for special characters
            processedSteps.push({
              output: token,
              pause: Math.random() * 50 + 20 + extraPause // Random pause between 50ms and 150ms + extra pause for special tokens
            });
          } else {
            processedSteps.push({
              output: token,
              pause: Math.random() * 60 + 30 // Random pause between 50ms and 150ms
            });
          }
          currentLine += token; // Add token to current line
        });
  
  
        // Insert an empty output step with the original step's pause after the last token
        processedSteps.push({
          output: '',
          pause: step.pause
        });

      } else {
        processedSteps.push(step);
      }
    });
  
    return processedSteps;
  }

  const applyColor = (text) => {
    return text.replace(/{([A-Z]{2})}/g, (match, p1) => `<span class="color-${p1.toLowerCase()}">`).replace(/}/g, '</span>');
  };
  
  
 /*
  const applyColor = (text) => {
    return text.split(/(\{[WCYG]\})/g).map((part, index) => {
      if (part.match(/^\{[WCYG]\}$/)) {
        const color = part[1];
        return <span key={index} className={colorMap[color]} />;
      } else {
        return part;
      }
    });
  };
*/

  const initialOneliner = '{CY}$ {WH}source <(curl -sL oneliners.io)';
  
  const useCasesPreProcessed = [ 
    {
      name: 'Login Simulation',
      steps: [
        { output: '{DG}# From any Internet connected system load the ol tool into memory\n', pause: 0 },
        { output: '{YE}$ ', pause: 1000 },
        { typing: '{WH}source <(curl -sL oneliners.io)', pause: 100 },
        { output: '\n{WH}Please enter your email: ', pause: 2500 },
        { typing: '{CY}john.doe@example.com', pause: 800 },
        { output: '\n{WH}Password:', pause: 800 },
        { typing: '{CY} ********', pause: 800 },
        { output: '{GR}\n\nLogin successful\n\n', pause: 1500 },
        { output: '{WH}Oneliners.io loaded for context {YE}john@fedora\n', pause: 100 }
      ]
    },
    {
      name: 'File Operations',
      steps: [
        { output: '{DG}# Augmentation example #1 - {LG}Finding some files{DG}\n', pause: 7000 },
        { output: '{DG}# Use the ol command followed by the command you want augmented\n', pause: 2000 },
        { typing: '{YE}$ ol ', pause: 1000 },
        { typing: '{WH}find files owned by me larger than 500mb', pause: 1800 },
        { output: '{CY}\nfind / -user $(whoami) -type f -size +500M\n', pause: 2500 }, /* wait for AI backend */
        { output: '{GR}-rw-r--r-- 1 john john 620M Oct 12 10:00 {YE}/home/john/bigfile3.mp4\n', pause: 1100 },
        { output: '{GR}-rw-r--r-- 1 john john 530M Oct 13 10:00 {YE}/home/john/bigfile4.mp4\n', pause: 80 },
        { output: '{GR}-rw-r--r-- 1 john john 580M Oct 14 10:00 {YE}/home/john/bigfile5.mp4\n', pause: 60 }
      ]
    },
    {
      name: 'K8s commands',
      steps: [
        { output: '{DG}# Augmentation example #2 - {LG}K8s kubectl command{DG}\n', pause: 7000 },
        { output: '{DG}# Use the ol command followed by the command you want augmented\n', pause: 2000 },
        { typing: '{YE}$ ol ', pause: 1000 },
        { typing: '{WH}list all non running pods in my namespace', pause: 1800 },
        { output: '{CY}\nkubectl get pods --field-selector=status.phase!=Running -n $(kubectl config view --minify --output \'jsonpath={..namespace}\')"\n', pause: 2500 }, /* wait for AI backend */
        { output: '{GR}NAME               READY   {YE}STATUS             {GR}RESTARTS   AGE\n', pause: 1100 },
        { output: '{GR}backend-api        0/1     {YE}CrashLoopBackOff   {GR}5          40m\n', pause: 80 },
        { output: '{GR}frontend           0/1     {YE}Pending            {GR}0          5m\n', pause: 60 },
        { output: '{GR}redis-cache        1/1     {YE}Terminating        {GR}0          2h\n', pause: 60 },
        { output: '', pause: 5000 } // Empty output to keep the last step on screen
      
      ]
    },
    {  
      name: 'Command Completion',
      steps: [
        { output: '{DG}# Command completion example #1 - {LG}Explicit Augmentation{DG}\n', pause: 1000 },
        { output: '{DG}# Use the ol command followed by {OR}<tab>{DG} to start command completion\n', pause: 2000 },
        { typing: '{YE}$ ol {TAB=augment;admin ask augment} {WH}find files owned by me larger than 500mb', pause: 2500 },
        { output: '{CY}\nfind / -user $(whoami) -type f -size +500M\n', pause: 2500 },
        { output: '{GR}-rw-r--r-- 1 john john 620M Oct 12 10:00 {YE}/home/john/bigfile3.mp4\n', pause: 1100 },
        { output: '{GR}-rw-r--r-- 1 john john 530M Oct 13 10:00 {YE}/home/john/bigfile4.mp4\n', pause: 80 },
        { output: '{GR}-rw-r--r-- 1 john john 580M Oct 14 10:00 {YE}/home/john/bigfile5.mp4\n', pause: 60 }
      ]
    },
    {  
      name: 'Command Completion #2',
      steps: [
        { output: '{DG}# Command completion example #2 - {LG}Explicit Feedback{DG}\n', pause: 2000 },
        { output: '{DG}# Use the ol command followed by {OR}TAB{DG} to start command completion\n', pause: 200 },
        { typing: '{YE}$ ol {TAB=feedback;feedback} {TAB=negative;negative positive} {TAB=accuracy;accuracy eliability usability} ', pause: 2500 },
        { typing: '{WH}asked for help but my request got augmented instead', pause: 1800 },
        { output: '{CY}\nFeedback has been collected, thanks for your participation!\n', pause: 1200 }
      ]
    },
    {  
      name: 'Asking questions',
      steps: [
        { output: '{DG}# Asking questions\n', pause: 2000 },
        { typing: '{YE}$ {WH}ol what is a pod?', pause: 2500 },  
        { output: '\n{OR}Answer: In Kubernetes (k8s) context, pods are used to group together related containers that need to be managed as a single unit. For example, if you have a web server and a database that need to communicate with each other, you can run the web server in one container and the database in another container, and then put them both in the same pod. This way, Kubernetes can manage both containers together, such as starting or stopping both of them when needed.', pause: 8500 },
      ]
    },
    {  
      name: 'Destructive commands',
      steps: [
        { output: '{DG}# Safeguards for potentially destructive commands\n', pause: 2000 },
        { typing: '{YE}$ {WH}ol remove all files on my laptop', pause: 2500 },  
        { output: '\n{CY}rm -rf /', pause: 800 },
        { output: '\n\n{RE}Warning: The command will wipe ALL files and the system will cease to work.', pause: 200 },
        { output: '\n{RE}         Are you sure? (Yes/No)', pause: 500 },
        { typing: '{WH} No', pause: 2500 },
        { output: '\n\n{YE}Augmentation aborted.', pause: 500 },
      ]
    },
  ];

  const useCases = useCasesPreProcessed.map(useCase => ({
    ...useCase,
    steps: preprocessSteps(useCase.steps)
  }));

  console.log(useCases);

  const [useCaseText, setUseCaseText] = useState("");
  const [currentUseCase, setCurrentUseCase] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [displayText, setDisplayText] = useState(applyColor(initialOneliner));
  const [isCopied, setIsCopied] = useState(false);
  const [isDemoActive, setIsDemoActive] = useState(false);
  const [shouldHideButton, setShouldHideButton] = useState(false);
//  const timeoutRef = useRef(null);
  const timeoutRef = useRef([]); // Initialize as an array

  /*
  console.log("Start: initialOneliner: ", initialOneliner);
  console.log("Start: color(initialOneliner): ", applyColor(initialOneliner));
  */
  const copyToClipboard = () => {
    navigator.clipboard.writeText("source <(curl -sL oneliners.io)").then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500); // Reset the animation state after 1.5 seconds
    });
  };

  const previousUseCase = () => {
    setUseCaseText("");
    setCurrentStep(0);
    //clearAllTimeouts();
    setCurrentUseCase((prevUseCase) => (prevUseCase - 1) % useCases.length);
  }

  const nextUseCase = () => {
    setUseCaseText("");
    setCurrentStep(0);
    //clearAllTimeouts();
    setCurrentUseCase((prevUseCase) => (prevUseCase + 1) % useCases.length);
  }

  const toggleDemo = () => {
    if (!isDemoActive) {
      setIsDemoActive(true);
      setShouldHideButton(true);  // Ensuring the button is hidden right when the demo starts
      setCurrentStep(0);
      setCurrentUseCase(0);
      //setDisplayText(applyColor(initialOneliner));
      setTimeout(() => {
        console.log("FRIPPE");
        //setDisplayText(`${applyColor(initialOneliner)}`);
      }, 800); // Wait for 1 second before showing the initial response
    } else {
      setIsDemoActive(false);
      setShouldHideButton(false);  // Reveal the copy button when demo ends
      setCurrentStep(0);
      setCurrentUseCase(0);
      
      setDisplayText(`${applyColor(initialOneliner)}`);
      //setUseCaseText("");
      //setDisplayText(applyColor(useCases[0].steps[0].command)); // Display first command of the first use case
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    }
  };

  const clearAllTimeouts = () => {
    timeoutRef.current.forEach(timeout => clearTimeout(timeout));
    timeoutRef.current = [];
  };

  
  useEffect(() => {
    if (isDemoActive) {
      const steps = useCases[currentUseCase].steps;
      const step = steps[currentStep];

      // Log usecase and step
      console.log("useCase: ", useCases[currentUseCase].name);
      console.log("step: ", currentStep);
   
      if (currentStep === 0) {
        // Reset the use case text for first step
        setUseCaseText(step.output);
      } else {
        // Add the command to the use case text
        setUseCaseText(useCaseText + step.output );
      } 

      console.log("useCaseText: ", useCaseText);

      setDisplayText(`${applyColor(useCaseText)}`);
    
      //setDisplayText(applyColor(useCaseText));

      //setDisplayText(prevText => [...prevText, ...updatedText]);

      timeoutRef.current.push(setTimeout(() => {
        let nextStep = currentStep + 1;
        if (nextStep >= steps.length) {
          nextStep = 0;
          setCurrentUseCase((prevUseCase) => (prevUseCase + 1) % useCases.length);
        }
        setCurrentStep(nextStep);
      }, step.pause || DEFAULT_PAUSE));
    }

    return () => {
      clearAllTimeouts();
    };
  }, [isDemoActive, currentStep, currentUseCase]);


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Oneliners.io Logo" className="logo" />
        <h3>The only oneliner you'll ever need!</h3>
        <div className={`code-container ${isDemoActive ? 'demo-active' : ''}`}>
          <pre className={`${isDemoActive ? 'demo-active' : ''}`}>
            
            <span className={`oneliner ${isDemoActive ? 'demo-active' : ''}`} id="oneliner" dangerouslySetInnerHTML={{ __html: displayText }}></span>
          </pre>
          {!shouldHideButton && (
            <button className={`copy-btn ${isCopied ? 'copied' : ''} ${isDemoActive ? 'fade-out' : ''}`} onClick={copyToClipboard}>
              <i className="far fa-copy"></i>
            </button>
          )}
        </div>
      </header>

      <footer className="App-footer">
        <button className={`demo-step-btn ${isDemoActive ? 'active' : ''}`} onClick={previousUseCase}>
          <i className="far fa-circle-left"></i>
        </button>

        <button className={`demo-btn ${isDemoActive ? 'active' : ''}`} onClick={toggleDemo}>
          <i className={`far ${isDemoActive ? 'fa-stop-circle' : 'fa-play-circle'}`}></i>         
        </button>

        <button className={`demo-step-btn ${isDemoActive ? 'active' : ''}`} onClick={nextUseCase}>
          <i className="far fa-circle-right"></i>
        </button>
      </footer>
    </div>
  );
} 

export default App;
